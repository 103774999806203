.Header {
  &__img {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin-right: 1rem;
  }
}

.offcanvas {
  --bs-offcanvas-bg: rgb(13, 110, 253);
}

.text-black {
  color: black;
}
